import { ButtonProps } from "@/components/Button/types/ButtonProps";
import clsx from "clsx";
import { cloneElement, forwardRef, isValidElement } from "react";
import { tv } from "tailwind-variants";

const buttonStyle = tv({
  base: [
    "inline-grid grid-flow-col place-items-center place-content-center text-sm font-bold rounded-xs text-center box-border px-16 truncate",
    "transition duration-240 ease-in-out",
    "[&_svg]:w-[16px] [&_svg]:h-[16px]",
  ],
  variants: {
    color: {
      primary: [
        "bg-gray-950 text-white",
        "hover:bg-gray-800",
        "[&_svg]:fill-white",
      ],
      outlined: [
        "bg-white-50 text-primary border",
        "hover:bg-gray-50",
        "[&_svg]:fill-black",
      ],
      "white-outlined": [
        "bg-white-50 text-white border border-white",
        "hover:bg-gray-600",
        "[&_svg]:fill-white",
      ],
      neutral: [
        "bg-gray-100 text-primary",
        "hover:bg-gray-200",
        "[&_svg]:fill-black",
      ],
      secondary: [
        "bg-white text-primary",
        "hover:bg-gray-50",
        "[&_svg]:fill-black",
      ],
      danger: [
        "border border-red-500 text-red-500",
        "hover:bg-red-500 hover:bg-opacity-5",
        "[&_svg]:fill-danger",
      ],
      ghost: ["bg-white text-primary", "hover:bg-gray-50"],
    },
    size: {
      large: "h-[48px] leading-[48px]",
      small: "h-[40px] leading-[40px]",
    },
    disabled: {
      true: "!border !border-primary !bg-white !text-gray-200 [&_svg]:!fill-gray-200",
    },
  },
});

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    color = "primary",
    size = "large",
    disabled,
    leftIcon,
    rightIcon,
    asChild = false,
    children,
    ...buttonProps
  },
  ref,
) {
  const shouldActAsChild = asChild && isValidElement(children);

  return cloneElement(
    shouldActAsChild ? (
      disabled ? (
        <div aria-disabled />
      ) : (
        children
      )
    ) : (
      <button ref={ref} type="button" disabled={disabled} {...buttonProps} />
    ),
    {
      className: clsx(
        buttonStyle({ color, size, disabled }),
        shouldActAsChild && children.props.className,
        "className" in buttonProps && buttonProps.className,
      ),
    },
    leftIcon ? <span className={"inline-block mr-4"}>{leftIcon}</span> : null,
    <span className="leading-normal">
      {shouldActAsChild ? children.props.children : children}
    </span>,
    rightIcon ? (
      <span className={"inline-block ml-4 align-middle"}>{rightIcon}</span>
    ) : null,
  );
}) as React.FC<ButtonProps>;
export default Button;
