import { FC, PropsWithChildren, useEffect } from "react";
import { auth } from "@/utils/firebase";
import {
  fetchMe,
  setToken,
  setIsChanged,
  setEmailVerified,
  setAuthUser,
} from "@/stores/auth";
import axios from "axios";
import { useAppDispatch } from "@/stores/index";
import { AuthProvider } from "@/domain/values/AuthProvider";
import { getIdToken, onAuthStateChanged } from "firebase/auth";

const FirebaseAuth: FC<PropsWithChildren> = ({ children }) => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    axios.interceptors.request.use(async (config) => {
      const currentUser = auth.currentUser;

      if (currentUser && !config.headers.Authorization) {
        const token = await getIdToken(currentUser);
        appDispatch(setToken(token));
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
  }, [appDispatch]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await appDispatch(fetchMe());
        appDispatch(
          setAuthUser({
            displayName: user.displayName,
            email: user.email,
            providers: user.providerData.map(
              (d) => d.providerId as AuthProvider,
            ),
          }),
        );
        appDispatch(setEmailVerified(user.emailVerified));
      }
      appDispatch(setIsChanged(true));
    });

    return unsubscribe;
  }, [appDispatch]);

  return <>{children}</>;
};

export default FirebaseAuth;
