import {
  AddableNotePin,
  AddableProductVariationPin,
} from "@/features/pin/types";
import { AwardEntryEntity } from "@/web-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  keywords: string[];
  pins: (AddableProductVariationPin | AddableNotePin)[];

  // for 1stラウンド
  preVoteEntry?: AwardEntryEntity;
  votedEntry?: AwardEntryEntity;

  // for Finalラウンド
  preFinalVoteEntry?: AwardEntryEntity;
  finalVotedEntry?: AwardEntryEntity;
}

const initialState = {
  keywords: [],
  pins: [],

  preVoteEntry: undefined,
  voteEntry: undefined,

  preFinalVoteEntry: undefined,
  finalVoteEntry: undefined,
} as State;

const slice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addKeyword(state, action: PayloadAction<string>) {
      state.keywords = [action.payload, ...state.keywords]
        .filter((x, i, self) => {
          return self.indexOf(x) === i;
        })
        .slice(0, 8);
    },
    clearKeywords(state) {
      state.keywords = [];
    },
    // 投票前のエントリーを保存
    setPreVoteEntry(state, action: PayloadAction<AwardEntryEntity>) {
      state.preVoteEntry = action.payload;
    },
    // エントリーをクリア
    clearPreVoteEntry(state) {
      state.preVoteEntry = undefined;
    },

    // 投票前のエントリーを保存
    setPreFinalVoteEntry(state, action: PayloadAction<AwardEntryEntity>) {
      state.preFinalVoteEntry = action.payload;
    },
    // エントリーをクリア
    clearPreFinalVoteEntry(state) {
      state.preFinalVoteEntry = undefined;
    },
  },
});

export const {
  addKeyword,
  clearKeywords,
  setPreVoteEntry,
  clearPreVoteEntry,
  setPreFinalVoteEntry,
  clearPreFinalVoteEntry,
} = slice.actions;
export const historyReducer = slice.reducer;
