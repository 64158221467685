export const NODE_ENV = process.env.NODE_ENV;
export const BASIC_AUTH_USERNAME = process.env.BASIC_AUTH_USERNAME;
export const BASIC_AUTH_PASSWORD = process.env.BASIC_AUTH_PASSWORD;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const USER_CONTACT_URL = process.env.USER_CONTACT_URL;
export const CONTACT_URL = process.env.CONTACT_URL;
export const RECRUIT_URL = process.env.RECRUIT_URL;
export const GMAP_API_KEY = process.env.GMAP_API_KEY;
export const RELEASE_SAMPLE_IDS = process.env.RELEASE_SAMPLE_IDS;
export const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID;
export const BASE_URL = process.env.BASE_URL;
export const GA4_TRACKING_ID = process.env.GA4_TRACKING_ID;
export const API_KEY = process.env.API_KEY;
export const CLARITY_PROJECT_ID = process.env.CLARITY_PROJECT_ID;
export const SIGN_POST_IDS = process.env.SIGN_POST_IDS;
export const TECTURE_AWARD_2024_SLUG = "tecture-award-2024";
export const TECTURE_AWARD_2024_MIN_DATE = new Date("2023-01-01");
export const AWARD_URL = process.env.AWARD_URL;
export const MAGAZINE_URL = process.env.MAGAZINE_URL;

export const LINE_CLIENT_ID = process.env.LINE_CLIENT_ID;
export const LINE_CLIENT_SECRET = process.env.LINE_CLIENT_SECRET;
export const NEXT_AUTH_SECRET = process.env.NEXT_AUTH_SECRET;

export const ADOBE_FONTS_KIT_ID = process.env.ADOBE_FONTS_KIT_ID;
export const FINALIST_PROJECT_IDS = process.env.FINALIST_PROJECT_IDS as string;
export const TECTURE_AWARD_1ST_ROUND_RESULT_URL = `${AWARD_URL}/results/first-round`;
